<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-card-title class="text-no-wrap pt-0 ps-2">
          <v-row>
            <v-col
              cols="12"
              md="10"
            >
              <p class="mt-2">
                客户数量
              </p>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn-toggle
                v-model="text"
                mandatory
                color="primary"
              >
                <v-btn
                  value="all"
                  @click="choiceData('all')"
                >
                  累计
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex align-center pb-0 ps-2 mb-0">
          <div>
            <p
              class=" font-weight-semibold primary--text mb-2"
              style="font-size: 30px;"
            >
              {{ count }} 个
            </p>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AgentApi from '@/api/agent'

export default {
  props: {
  },
  data: () => ({
    count: 0,
  }),
  mounted() {
    this.choiceData()
  },
  methods: {
    choiceData() {
      AgentApi.getHomeAgentCount()
        .then(res => {
          this.count = res.data.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
