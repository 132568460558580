<template>
  <div v-if="$can('agent_list', 'agent')">
    <v-row>
      <v-col
        md="4"
        cols="12"
      >
        <MonthSalePrice></MonthSalePrice>
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <MonthOrderCount></MonthOrderCount>
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <AgentUserCount></AgentUserCount>
      </v-col>
    </v-row>
    <v-card class="mt-2">
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex flex-row-reverse pb-0">
          <div class="d-flex align-center">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <v-autocomplete
              id="userId"
              v-model="search.userId"
              outlined
              dense
              label="归属用户"
              item-text="nickName"
              item-value="id"
              :items="homeAgents"
            ></v-autocomplete>
          </div>
          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.endTime"
              :hide="false"
              label="结束时间"
            ></DatePick>
          </div>
          <div class="d-flex align-center mr-5">
            <span>至</span>
          </div>

          <div class="d-flex align-center pt-6 mr-5 pb-0">
            <DatePick
              v-model="search.startTime"
              :hide="false"
              label="开始时间"
            ></DatePick>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card
      class="mt-2"
    >
      <v-card-title class="">
        <v-tabs
          v-model="currentTab"
          style="box-shadow: none !important;"
        >
          <v-tab @click="reloadTab(null)">
            全部订单({{ orderCount.allCount }})
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          disable-filtering
          :headers="getHeader()"
          :items="orders"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <template #[`item.company`]="{ item }">
            <v-row>
              <v-col
                class="pa-2 align-self-center"
                cols="auto"
              >
                <v-avatar size="33">
                  <img
                    :src="item.receiverAvatar || require('@/assets/images/avatars/1.png')"
                  >
                </v-avatar>
              </v-col>
              <v-col class="pl-0">
                <span class="text-subtitle-2">{{ item.companyName }}  </span><br>
                <span
                  class="text-caption"
                >{{ item.receiverName }} {{ item.receiverPhone }}</span>
              </v-col>
            </v-row>
          </template>

          <template #[`item.orderPrice`]="{ item }">
            <span>￥{{ item.orderPrice }}</span>
          </template>

          <template #[`item.orderStatus`]="{ item }">
            <v-chip
              v-if="item.orderStatus === 2"
              color="info"
              small
              class="font-weight-semibold text-capitalize"
            >
              待付款
            </v-chip>
            <v-chip
              v-else
              :color="getOrderStatusColor(item.orderStatus)"
              small
              class="font-weight-semibold text-capitalize"
            >
              {{ getOrderStatusByValue(item.orderStatus) }}
            </v-chip>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="$can('agent_detail', 'agent')"
              text
              dense
              x-small
              color="primary"
              @click="editItem(item)"
            >
              详情
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? '80%' : '100%'"
      height="100%"
      app
    >
      <div class="drawer-header d-flex align-center">
        <span class="font-weight-semibold text-base text-h6">订单详情</span>
        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          @click="editDialog = false"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-row
        style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
        class="overflow-auto"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="pt-2 ml-5 overflow-auto overflow-auto"
          >
            <v-card-title class="justify-center flex-column">
              <v-avatar
                color="primary"
                class="v-avatar-light-bg primary--text"
                size="120"
                rounded
              >
                <v-img
                  :src="require('@/assets/images/avatars/1.png')"
                ></v-img>
              </v-avatar>
              <h4 class="mb-2 mt-3">
                {{ userInfo.userName }}
              </h4>

              <span class="mb-2 text-caption">{{ userInfo.memberName }}</span>
            </v-card-title>

            <v-card-text>
              <p class="text-h6">
                订货信息
              </p>

              <v-divider></v-divider>

              <v-list>
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">订货金额</span>
                  <span class="text--secondary">¥{{ order.orderPrice }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium text-no-wrap me-2">下单时间</span>
                  <span class="text--secondary">{{ order.createdTime }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">订单编号</span>
                  <span class="text--secondary text-capitalize">{{ order.orderNo }}</span>
                </v-list-item>

                <v-list-item
                  v-if="$can('order_toggle_status', 'order')"
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">订货状态</span>

                  <!-- 当前状态 -->
                  <v-chip
                    :color="getOrderStatusColor(order.orderStatus)"
                    small
                    class="font-weight-semibold text-capitalize"
                  >
                    {{ getOrderStatusByValue(order.orderStatus) }}
                  </v-chip>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-text>
              <p class="text-h6">
                订货单位
              </p>

              <v-divider></v-divider>

              <v-list>
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">单位名称</span>
                  <span class="text--secondary">{{ companyDetail.companyName }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium text-no-wrap me-2">收件人</span>
                  <span class="text--secondary">{{ companyDetail.receiverName }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">收货地址</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.receiverAddr }}{{ companyDetail.receiverAddrDetail }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">发票类型</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.invoiceType === 0 ? '普通发票': '增值税发票' }}</span>
                </v-list-item>
                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">发票抬头</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.companyName }}</span>
                </v-list-item>

                <v-list-item
                  dense
                  class="px-0 mb-n2"
                >
                  <span class="font-weight-medium me-2">单位税号</span>
                  <span class="text--secondary text-capitalize">{{ companyDetail.invoiceNo }}</span>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <p class="text-h6">
                    商品列表
                  </p>
                </v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            商品描述
                          </th>
                          <th class="text-left">
                            数量
                          </th>
                          <th class="text-left">
                            原价
                          </th>
                          <th class="text-left">
                            会员价
                          </th>
                          <th class="text-left">
                            结算价
                          </th>
                          <th class="text-left">
                            小计
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in orderDetails"
                          :key="index"
                        >
                          <td>
                            <v-row>
                              <v-col
                                class="pa-2 align-self-center"
                                cols="auto"
                              >
                                <v-avatar size="33">
                                  <img
                                    :src="item.productImg"
                                  >
                                </v-avatar>
                              </v-col>
                              <v-col class="pl-0">
                                <span class="text-subtitle-2">{{ item.productName }}  </span><br>
                                <span
                                  class="text-caption"
                                >{{ item.productSkuTitle }}</span>
                              </v-col>
                            </v-row>
                          </td>
                          <td>
                            {{ item.productSkuQuantity }}
                          </td>
                          <td>¥{{ parseFloat(item.productSkuPrice).toFixed(2) }}</td>
                          <td>¥{{ parseFloat(item.productSkuMemberPrice).toFixed(2) }}</td>
                          <td>
                            ¥{{ parseFloat(item.settlementPrice).toFixed(2) }}
                          </td>
                          <td>¥{{ parseFloat(item.subTotalPrice).toFixed(2) }}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>合计</td>
                          <td>
                            ¥{{ parseFloat(order.orderPrice).toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiLinkVariant,
  mdiFileDocumentOutline,
} from '@mdi/js'
import DatePick from '@/components/DatePicker'
import OrderApi from '@/api/order'
import AgentApi from '@/api/agent'
import MonthSalePrice from './MonthSalePrice'
import MonthOrderCount from './MonthOrderCount'
import AgentUserCount from './AgentUserCount'

export default {
  name: 'Agent',
  components: {
    DatePick,
    MonthSalePrice,
    MonthOrderCount,
    AgentUserCount,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiLinkVariant,
        mdiFileDocumentOutline,
      },
      editDialog: false,
      deleteDialog: false,
      orders: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      search: {},
      editedItem: {
        startTime: '',
        endTime: '',
      },
      currentTab: null,
      status: 0,
      deleteData: {},
      orderCount: {
        allCount: 0,
        waitConfirmCount: 0,
        waitPayCount: 0,
        waitDeliveryCount: 0,
        waitReceiveCount: 0,
        completedCount: 0,
        cancelCount: 0,
      },
      order: {},
      orderDetails: [],
      companyDetail: {},
      userInfo: {
        memberName: '',
        userName: '',
      },
      statusList: [
        { text: '待确认', color: 'danger', value: 0 },
        { text: '已取消', color: '', value: 1 },
        { text: '已通过', color: 'info', value: 2 },
        { text: '已付款', color: 'warning', value: 3 },
        { text: '已发货', color: 'primary', value: 4 },
        { text: '已完成', color: 'success', value: 5 },
      ],
      homeAgents: [],
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '订单编号', value: 'orderNo' },
        { text: '收货信息', value: 'company' },
        { text: '订货金额', value: 'orderPrice' },
        { text: '下单时间', value: 'createdTime' },
        { text: '状态', value: 'orderStatus' },
      ]

      if (this.$can('agent_detail', 'agent')
      ) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    loadPage({ page, itemsPerPage }) {
      let startTime = ''
      if (this.search.startTime) {
        startTime = `${this.search.startTime} 00:00:00`
      }
      let endTime = ''
      if (this.search.endTime) {
        endTime = `${this.search.endTime} 23:59:59`
      }
      const searchData = {
        rows: itemsPerPage,
        page,
        startTime,
        endTime,
        userId: this.search.userId,
      }
      AgentApi.getPages(searchData)
        .then(response => {
          const { pages, countDTO, homeAgents } = response.data.data
          this.orderCount = countDTO
          this.orders = pages.records
          this.homeAgents = homeAgents
          if (pages.size === -1) {
            this.pages.serverItemsLength = this.orders.length
          } else {
            this.pages.serverItemsLength = pages.total
          }
        })
    },
    initOrderDetail() {
      this.companyDetail = {}
      this.order = {}
      this.orderDetails = []
      this.userInfo.memberName = ''
      this.userInfo.userName = ''
    },
    editItem(item) {
      this.initOrderDetail()
      if (item) {
        this.loadDetailData(item.orderNo)
      }
      this.editDialog = true
    },
    loadDetailData(orderNo) {
      if (this.$can('agent_detail', 'agent')) {
        OrderApi.getOrderDetail(orderNo).then(res => {
          const {
            detailResponse, memberName, userName,
          } = res.data.data
          const {
            orderDTO, detailDTOS, addressDTO,
          } = detailResponse
          this.companyDetail = addressDTO
          this.order = orderDTO

          this.orderDetails = detailDTOS
          this.userInfo.memberName = memberName
          this.userInfo.userName = userName
        })
      }
    },
    reloadTab(status) {
      this.status = status

      this.toSearch()
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.search.startTime = ''
      this.search.endTime = ''
      this.toSearch()
    },
    getCurrentUserName() {
      const userDataStr = localStorage.getItem('userData')
      const userData = JSON.parse(userDataStr)
      const { fullName } = userData

      return fullName
    },
    getCurrentTime() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      const createdTime = `${year}-${month}-${day} ${hour}:${minute}`

      return createdTime
    },
    getOrderStatusByValue(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.text : ''
    },
    getOrderStatusColor(value) {
      const status = this.statusList.find(item => item.value === value)

      return status ? status.color : ''
    },
  },
}
</script>

<style>
.fixed-top {
  position: fixed;
  top: 0;
  /* width: 100%; */
}
</style>
