import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/agent'
export default {

  getSaleAmount(data) {
    return axios.post(`${prefixPath}/getSaleAmount`, data)
  },
  getOrderCount(data) {
    return axios.post(`${prefixPath}/getOrderCount`, data)
  },
  getHomeAgentCount() {
    return axios.get(`${prefixPath}/getHomeAgentCount`)
  },
  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },
}
